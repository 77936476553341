import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ReservationComponent } from './components/reservation/reservation.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { BottomComponent } from './components/bottom/bottom.component';
import { DescriptionComponent } from './components/description/description.component';
import { MenuComponent } from './components/menu/menu.component';
import { AccomodationListComponent } from './components/accomodation-list/accomodation-list.component';
import { AccomodationItemComponent } from './components/accomodation-item/accomodation-item.component';
import { MenuService } from './components/menu/menu.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ContactComponent } from './components/contact/contact.component';
import { DirectionsComponent } from './components/locations/directions.component';
import { LoaderComponent } from './components/loader/loader.component';
import { HamburgerComponent } from './components/hamburger/hamburger.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DescriptionItemComponent } from './components/description-item/description-item.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccomodationDetailComponent } from './components/accomodation-detail/accomodation-detail.component';
import { ModalComponent } from './components/modal/modal.component';
import { ButtonComponent } from './components/button/button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxGlideModule } from 'ngx-glide';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CarouselComponent,
    ReservationComponent,
    BottomComponent,
    DescriptionComponent,
    MenuComponent,
    AccomodationListComponent,
    AccomodationItemComponent,
    ContactComponent,
    DirectionsComponent,
    LoaderComponent,
    HamburgerComponent,
    GalleryComponent,
    DescriptionItemComponent,
    AccomodationDetailComponent,
    ModalComponent,
    ButtonComponent,

  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoicGFubmFsYXdlbmRhIiwiYSI6ImNrMzh1bjFxajBjaHozYnBpOGt2Y3ZwbzQifQ.Nmsuuz0qm3mcBtNkKEh_Pg',
      geocoderAccessToken: 'pk.eyJ1IjoicGFubmFsYXdlbmRhIiwiYSI6ImNrMzh1bjFxajBjaHozYnBpOGt2Y3ZwbzQifQ.Nmsuuz0qm3mcBtNkKEh_Pg'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }
    }),
    FontAwesomeModule,
    AngularSvgIconModule.forRoot(),
    NgxGlideModule
  ],
  providers: [MenuService],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
