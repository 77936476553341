import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MenuService } from './menu.service';
import { faGlobeAmericas, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { TranslationsService } from '../shared/translations.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers:[TranslationsService]
})
export class MenuComponent implements OnInit {
  prevScroll: number;
  scrollDown$ = this.menuService.scrollDown$;
  faGlobeAmericas = faGlobeAmericas;
  faPhoneAlt = faPhoneAlt;

  constructor(private menuService: MenuService, private translationsService: TranslationsService) {

    this.menuService.scrollDirection$.subscribe(() => {
      this.menuService.determineScrollDirection(this.prevScroll, window.scrollY);

      this.prevScroll = window.scrollY;
    });
  }

  ngOnInit() {
  }

  toBottom() {
    document.getElementById('contact').scrollIntoView({behavior: 'smooth'});
  }

  changeLang(lang: string) {
    this.translationsService.changeLang(lang);
  }
}
