import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { fromEvent, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() accomodationId;
  @Output() closeGallery: EventEmitter<any> = new EventEmitter();
  @ViewChild('carousel', { static: false }) carousel: ElementRef;
  arrowEvents: Subscription;
  constructor(private http: HttpClient) { }

  galleryItems$;

  ngOnInit() {
    this.galleryItems$ = this.http.get('api/accomodations/' + this.accomodationId + '/pictures');
    this.arrowEvents = fromEvent(document, 'keydown')
      .pipe(
        filter((event: KeyboardEvent) => event.keyCode === 27)
      )
      .subscribe((event) => {
        this.closeGallery.emit();
      });

  }

  ngAfterViewInit() {
    setTimeout(() => {
      Array.from(document.getElementsByClassName('carousel')).forEach((el: HTMLElement) => {
        el.focus();
      });
    }, 1000);
  }

  ngOnDestroy() {
    this.arrowEvents.unsubscribe();
  }

}
