import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { NgxGlideComponent } from 'ngx-glide';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, AfterViewInit {
  @ViewChild(NgxGlideComponent, { static: false }) ngxGlide: NgxGlideComponent;
  @Input() slides;
  @Input() setup;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }

}
