import { Component, OnInit } from '@angular/core';
import { faCouch, faCar, faFire, faSmile, faWheelchair, faLeaf  } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})

export class DescriptionComponent implements OnInit {

  descriptionItems = ['home.car', 'home.fire', 'home.smile', 'home.couch', 'home.wheel', 'home.green'];
  setup = {
    2560: {
      type: 'carousel',
      perView: 3,
    },
    1200: {
      type: 'carousel',
      perView: 2,
    },
    800: {
      type: 'carousel',
      perView: 1,
    }
  };
  slides = [
    '../../../assets/images/hero0c.jpg', '../../../assets/images/home2.jpg',
    '../../../assets/images/home1.jpg', '../../../assets/images/home4.jpg', '../../../assets/images/home8.jpg',
    '../../../assets/images/home5.jpg', '../../../assets/images/s1t_2.jpg', '../../../assets/images/s2t_3.jpg',
  ];
  icons = {
    'home.car': faCar,
    'home.fire': faFire,
    'home.smile': faSmile,
    'home.couch': faCouch,
    'home.wheel': faWheelchair,
    'home.green': faLeaf
  };

  ngOnInit() {
  }

}

