import { Component, Input, AfterContentInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { faAnchor } from '@fortawesome/free-solid-svg-icons';
import { singularTypes } from '../shared/model';


@Component({
  selector: 'app-accomodation-item',
  templateUrl: './accomodation-item.component.html',
  styleUrls: ['./accomodation-item.component.scss']
})
export class AccomodationItemComponent implements AfterContentInit {

  @Input() accomodation;
  accomodationImage$: BehaviorSubject<string> = new BehaviorSubject(null);
  accomodationId: number;
  faAnchor = faAnchor;
  private priceFrom: number;
  modalState = false;
  accomodationTypes = singularTypes;
  popupState = false;
  constructor() { }

  ngAfterContentInit() {
    this.accomodationImage$.next('../../../assets/images/' + this.accomodation.mainPicture);
    this.accomodationId = this.accomodation.accomodationId;
    this.priceFrom = Math.round(this.accomodation.price / this.accomodation.guestCount);
  }

  toggleScroll(value: boolean): void {
    document.body.style.overflow = value ? 'hidden' : 'auto';
  }

  openGallery(): void {
    this.popupState = true;
    this.toggleScroll(true);
  }

  closeGallery(): void {
    this.popupState = false;
    this.toggleScroll(false);
  }

  openModal(): void {
    this.modalState = true;
    this.toggleScroll(true);
  }

  closeModal(): void {
    this.modalState = false;
    this.toggleScroll(false);
  }
}
