import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationsService } from '../shared/translations.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss'],
  providers: [TranslationsService]
})
export class HamburgerComponent implements OnInit {
  open = false;
  nextLang: BehaviorSubject<string>;
  constructor(private router: Router, private tranlationsService: TranslationsService) { }

  ngOnInit() {
    this.nextLang = this.tranlationsService.nextLang$;
  }

  toggleMenu() {
    this.open = !this.open;
    document.body.style.overflow = this.open ? 'hidden' : 'auto';

    if (this.open) {
      document.querySelector('.menu-wrapper').classList.add('open');
    } else {
      document.querySelector('.menu-wrapper').classList.remove('open');
    }

  }

  navigateTo(link: string): void {
    this.toggleMenu();
    this.router.navigateByUrl(link);
  }

  toBottom() {
    document.getElementById('contact').scrollIntoView({ behavior: "smooth" });
    this.toggleMenu();
  }

  switchLang(lang) {
    this.tranlationsService.changeLang(lang);
    this.toggleMenu();
  }
}
