import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TranslationsService {
  nextLang$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private translate: TranslateService) {

    this.translate.onLangChange.subscribe(() => {

      const nextLang = this.translate.currentLang === 'en' ? 'pl' : 'en';
      this.nextLang$.next(nextLang);
    });

  }

  changeLang(lang: string) {
    this.translate.use(lang);
  }

  getCurrentLang() {
   return this.translate.currentLang;
  }

}