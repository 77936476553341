import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {
  translations$;
  constructor(private translate: TranslateService) { }


  translated() {
    this.translations$ = this.translate.get(
      ['reservation.rule_1', 'reservation.rule_2', 'reservation.rule_3', 'reservation.rule_4', 'reservation.rule_5'])
      .pipe(
        map(values => Object.keys(values).map(key => values[key]))
      );
  }

  ngOnInit() {

    if (!this.translations$) {
      this.translated();
    }

    this.translate.onLangChange.subscribe(() => this.translated());
  }
}
