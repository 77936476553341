import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, map, take } from 'rxjs/operators';
import { faAnchor } from '@fortawesome/free-solid-svg-icons';
import { accomodationTypes } from '../shared/model';

enum accomodationsTypesEnum {
  houses,
  caravans,
  apartments,
}

@Component({
  selector: 'app-accomodation-list',
  templateUrl: './accomodation-list.component.html',
  styleUrls: ['./accomodation-list.component.scss']
})

export class AccomodationListComponent implements OnInit {


  accomodationTypes = accomodationTypes;

  accomodationsNames = [
    'typeDescriptions.houses',
    'typeDescriptions.caravans',
    'typeDescriptions.studios',
];

  accomodations$: Observable<any>;
  accomodationType: number;

  faAnchor = faAnchor;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.accomodations$ = this.route.paramMap.pipe(
      switchMap((params) => {
        const accomodationType = params.get('category');
        return this.http.post('/api/accomodations/search', { AccomodationType: [accomodationsTypesEnum[accomodationType]] });
      }),
      map((res) => {
        return (res as any).accomodations.reverse();
      }),
      );
  }

}
