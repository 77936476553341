import { Injectable } from '@angular/core';
import { tap, throttleTime } from 'rxjs/operators';
import { fromEvent, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  scrollDown: boolean;
  scrollDown$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  scrollDirection$ = fromEvent(window, 'scroll').pipe(
    throttleTime(100)
  );

  determineScrollDirection(prev, current) {
    this.scrollDown = prev < current;
    this.scrollDown$.next(this.scrollDown);
  }

}
