import { Component, OnInit } from '@angular/core';
import { accomodationTypes } from '../shared/model';

@Component({
  selector: 'app-directions',
  templateUrl: './directions.component.html',
  styleUrls: ['./directions.component.scss']
})
export class DirectionsComponent implements OnInit {

  accomodationTypes = accomodationTypes;

  constructor() { }

  ngOnInit() {
  }
}
