import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  @ViewChild('marker', { read: ElementRef, static: false })
  marker: ElementRef;

  @ViewChild('anchor', { read: ElementRef, static: false })
  anchor: ElementRef;

  geojson = {
    type: 'FeatureCollection',
    feature: [
      {
        type: 'Feature',
        properties: {
          message: 'Foo',
          iconSize: [60, 60]
        },
        geometry: {
          type: 'Point',
          coordinates: [
            18.4057896,
            54.7851322
          ]
        }
      },

    ]
  };

  setup = {
    2560: {
      type: 'carousel',
      perView: 3,
    },
    800: {
      type: 'carousel',
      perView: 1.5,
      focusAt: 'center',
    }
  };

  slides = [
    '../../../assets/images/home6.jpg', '../../../assets/images/home1.jpg',
    '../../../assets/images/home2.jpg', '../../../assets/images/home4.jpg', '../../../assets/images/home8.jpg',
    '../../../assets/images/home5.jpg', '../../../assets/images/s1t_2.jpg', '../../../assets/images/s2t_3.jpg',
  ];

  constructor() { }
}
