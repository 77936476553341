import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-accomodation-detail',
  templateUrl: './accomodation-detail.component.html',
  styleUrls: ['./accomodation-detail.component.scss']
})
export class AccomodationDetailComponent implements OnInit {
@Input() text: string;
@Input() value: string;
@Input() isPrice = false;
  constructor() { }

  ngOnInit() {
  }

}
